import Vue from 'vue'
import Vuex from 'vuex'
import CoverLetter from "@/services/coverletter";
import Email from "@/services/email";
import Archive from "@/services/archive";
import PDF from "@/utils/pdf";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {
        "coverLetter/generateCoverLetter": async function ({commit}, payload) {
            let promptText = "";
            if (!payload.jobTitle) {
                throw new Error("Job title is required");
            } else if (payload.jobTitle && payload.jobDescription && payload.jobDescription.length > 100) {
                promptText = `Create cover letter to apply for a job title of  ${payload.jobTitle.toLowerCase()} using the job description ${payload.jobDescription.toLowerCase()}`
            } else {
                promptText = `Create cover letter to apply for a job title of ${payload.jobTitle.toLowerCase()}`
            }
            let coverLetter = await CoverLetter.generateCoverLetter({
                promptText: promptText
            });
            return coverLetter;
        },
        "coverLetter/saveCoverLetter": async function ({commit}, payload) {
            let coverLetter = await CoverLetter.saveCoverLetter(payload);
            return coverLetter;
        },
        "coverLetter/getCoverLetter": async function ({commit}, payload) {
            let coverLetter = await CoverLetter.getCoverLetter(payload);
            return coverLetter;
        },
        "email/sendEmail": async function ({commit}, payload) {
            let email = await Email.sendEmail(payload);
            return email;
        },
        "archive/createArchive": async function ({commit}, payload) {
            let archive = await Archive.createArchive(payload);
            return archive;
        },
        "archive/getArchive": async function ({commit}, payload) {
            let archive = await Archive.getArchive(payload);
            return archive;
        },
        "pdf/create": async function ({commit}, payload) {
            let pdf = await PDF.create(payload);
            return pdf;
        }
    },
    modules: {}
})

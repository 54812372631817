<template>
  <b-form-group
      :label="label"
      :class="inputClass">
    <b-form-select
        v-model="value"
        :placeholder="label"
        :required="required"
        :options="options"
        :value-field="valueFieldName"
        :text-field="textFieldName"
        @change="onChange"
    ></b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: "BaseFormSelect",
  props: {
    valueFieldName: {
      type: String,
      default: "value"
    },
    textFieldName: {
      type: String,
      default: "text"
    },
    inputClass: {
      type: String,
      default: "text-start p-1 fw-bold flex-fill",
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
      default: true
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    onChange() {
      this.$emit("input", this.value);
    }
  }

}
</script>

<style scoped>
select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e3f3a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

</style>
<template>
  <div class="about">
    <b-jumbotron
        class="m-0"
        fluid
        style="background-color: #f5f5f5"
        header="Welcome to Kazicraft"
        lead="A cloud based application that helps you write persuasive job applications"></b-jumbotron>

    <div class="w-100 d-flex justify-content-center">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoverLetterView',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<template>
  <div class="home">
    <div>
      <b-jumbotron
          class="m-0"
          fluid
          style="background-color: #f5f5f5"
          header="Welcome to Kazicraft"
          lead="A cloud based application that helps you write persuasive job applications"></b-jumbotron>
      <div class="w-100 d-flex justify-content-center">
        <div class="d-flex">
          <b-card
              class="m-2"
              title="Create a cover letter"
              tag="article"
              style="max-width: 20rem;"
              bg-variant="light"
              text-variant="dark"
          >
            <img src="../assets/images/cover-letter.jpeg" width="250" height="150">

            <b-card-body>
              <b-button id="cover-letter" variant="primary" class="container-fluid" @click="navigateToRouteName('new-cover-letter')">
                Start
              </b-button>
            </b-card-body>
          </b-card>
          <b-card
              class="m-2"
              title="Create a resume"
              tag="article"
              style="max-width: 20rem;"
              bg-variant="light"
              text-variant="dark"
          >
            <img src="../assets/images/resume.jpg" width="250" height="150">
            <b-card-body>
              <b-button id="resume" variant="primary" class="container-fluid" @click="this.$notify({
                title: 'Coming soon',
                variant: 'info',
                solid: true
              })">Coming soon
              </b-button>
            </b-card-body>


          </b-card>
          <b-card
              class="m-2"
              title="Create a linkedIn profile"
              tag="article"
              style="max-width: 20rem;"
              bg-variant="light"
              text-variant="dark"
          >

            <img src="../assets/images/profile.png" width="250" height="150">
            <b-card-body>
              <b-button id="linkedIn" variant="primary" class="container-fluid" @click="this.$notify({
                title: 'Coming soon',
                variant: 'info',
                solid: true
              })">Coming soon
              </b-button>
            </b-card-body>
          </b-card>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <header class="text-center">
          <h1 class="display-4">How it works</h1>
          <p class="lead">Kazicraft helps you write persuasive job applications. <br> The app allows you to custom make
            a
            cover letter and resume for every job description. <br> This increases the chances of getting invited for an
            interview</p>
        </header>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      startCoverLetter: false
    }
  },
  methods: {
    navigateToRouteName(name) {
      this.$router.push({name: name})
    }
  }
}
</script>

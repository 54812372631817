import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CoverLetterView from "@/views/CoverLetterView.vue";
import NewCoverLetter from "@/components/NewCoverLetter.vue";
import LinkCoverLetter from "@/components/LinkCoverLetter.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/cover-letter',
        name: 'cover-letters',
        component: CoverLetterView,
        children: [
            {
                name: 'new-cover-letter',
                path: 'generate',
                component: NewCoverLetter,
            },
            {
                name: 'link-cover-letter',
                path: 'link/:id',
                component: LinkCoverLetter,
            },
        ]

    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',[_c('b-jumbotron',{staticClass:"m-0",staticStyle:{"background-color":"#f5f5f5"},attrs:{"fluid":"","header":"Welcome to Kazicraft","lead":"A cloud based application that helps you write persuasive job applications"}}),_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('div',{staticClass:"d-flex"},[_c('b-card',{staticClass:"m-2",staticStyle:{"max-width":"20rem"},attrs:{"title":"Create a cover letter","tag":"article","bg-variant":"light","text-variant":"dark"}},[_c('img',{attrs:{"src":require("../assets/images/cover-letter.jpeg"),"width":"250","height":"150"}}),_c('b-card-body',[_c('b-button',{staticClass:"container-fluid",attrs:{"id":"cover-letter","variant":"primary"},on:{"click":function($event){return _vm.navigateToRouteName('new-cover-letter')}}},[_vm._v(" Start ")])],1)],1),_c('b-card',{staticClass:"m-2",staticStyle:{"max-width":"20rem"},attrs:{"title":"Create a resume","tag":"article","bg-variant":"light","text-variant":"dark"}},[_c('img',{attrs:{"src":require("../assets/images/resume.jpg"),"width":"250","height":"150"}}),_c('b-card-body',[_c('b-button',{staticClass:"container-fluid",attrs:{"id":"resume","variant":"primary"},on:{"click":function($event){return this.$notify({
              title: 'Coming soon',
              variant: 'info',
              solid: true
            })}}},[_vm._v("Coming soon ")])],1)],1),_c('b-card',{staticClass:"m-2",staticStyle:{"max-width":"20rem"},attrs:{"title":"Create a linkedIn profile","tag":"article","bg-variant":"light","text-variant":"dark"}},[_c('img',{attrs:{"src":require("../assets/images/profile.png"),"width":"250","height":"150"}}),_c('b-card-body',[_c('b-button',{staticClass:"container-fluid",attrs:{"id":"linkedIn","variant":"primary"},on:{"click":function($event){return this.$notify({
              title: 'Coming soon',
              variant: 'info',
              solid: true
            })}}},[_vm._v("Coming soon ")])],1)],1)],1)]),_vm._m(0)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('header',{staticClass:"text-center"},[_c('h1',{staticClass:"display-4"},[_vm._v("How it works")]),_c('p',{staticClass:"lead"},[_vm._v("Kazicraft helps you write persuasive job applications. "),_c('br'),_vm._v(" The app allows you to custom make a cover letter and resume for every job description. "),_c('br'),_vm._v(" This increases the chances of getting invited for an interview")])])])
}]

export { render, staticRenderFns }
import {jsPDF} from "jspdf";

// Default export is a4 paper, portrait, using millimeters for units


class PDF {
    constructor() {
        this.doc = new jsPDF();
    }

    async create(payload) {
        this.doc.text(payload.text, 10, 10, {
            align: "left",
            maxWidth: 180,
        });
        this.doc.save(payload.filename);
    }
}


export default new PDF();
import {Database} from "@/services/base/database";

class Archive extends Database {
    constructor() {
        super();
        this.table = "archive";
    }

    async getArchive(payload) {
        return this.getRecord(payload.id, this.table)
    }

    async getArchives(payload) {
        return this.getRecords(this.table)
    }

    async createArchive(payload) {
        return this.createRecord(payload, `${this.table}/${payload.id}/${payload.generated_id}`)
    }


}

export default new Archive();
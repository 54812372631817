import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.css'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
import Notifications from 'vue-notification'

Vue.use(Notifications)

Vue.config.productionTip = false


new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')

<template>
  <div class="d-flex w-100 justify-content-center p-5">
    <div class="col-md-4 col-sm-6 col-12 text-primary text-wrap text-start">
      <div id="cover-letter">
        <base-form-text-area label="Cover letter" :propText="coverLetter" :disabled="true"></base-form-text-area>
      </div>
      <div class="d-flex justify-content-between mt-5">
        <b-button variant="primary" @click="navigateToRouteName('new-cover-letter')">Back</b-button>
        <b-button-group>
          <b-button variant="info" @click="download()">
            Download
          </b-button>
        </b-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFormTextArea from "@/components/base/BaseFormTextArea.vue";

export default {
  name: "LinkCoverLetter",
  components: {BaseFormTextArea},
  props: {
    propCoverLetter: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      coverLetter: this.propCoverLetter
    }
  },
  mounted() {
    this.getCoverLetter();
  },
  methods: {
    async getCoverLetter() {
      let id = this.$route.params.id;
      let res = await this.$store.dispatch("coverLetter/getCoverLetter", {
        id: id
      })
      this.coverLetter = res.data.coverLetter;
    },
    navigateToRouteName(name) {
      return this.$router.push({name: name});
    },
    download(text, filename = "cover-letter.pdf") {
      let res = this.$store.dispatch("pdf/create", {
        text: this.coverLetter,
        filename: filename
      })
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
    }
  }
}
</script>

<style scoped>

</style>
import {Database} from "@/services/base/database";

class CoverLetter extends Database{
    constructor() {
        super();
        this.api = process.env.VUE_APP_OPENAI_API_URL
        this.table = "cover-letters"
    }

    async generateCoverLetter(payload) {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`);

        let body = JSON.stringify({
            model: "text-davinci-003",
            prompt: payload.promptText,
            max_tokens: 2048,
            temperature: Math.random() * 1.1,
        });

        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: body,
            redirect: 'follow'
        };

        let res = await fetch(this.api, requestOptions)
        let data = await res.json();
        return data.choices[0].text;
    }

    saveCoverLetter(payload) {
        return this.createRecord(payload, this.table)
    }

    updateCoverLetter(payload) {
        return this.updateRecord(payload.id, this.table, payload)
    }

    deleteCoverLetter(payload) {
        return this.deleteRecord(payload.id, this.table)
    }

    getCoverLetter(payload) {
        return this.getRecord(payload.id, this.table)
    }
}

export default new CoverLetter();
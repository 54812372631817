<template>
  <b-form class="col-12 col-md-8">
    <div v-if="generatingState == 'fill'">
      <b-alert variant="success" show>
        <div class="divider">
          <hr>
        </div>
        <div>
          <h2>Start a new cover letter</h2>
        </div>
        <div class="divider">
          <hr>
        </div>
      </b-alert>

      <base-form-input :required="true" type="text" label="Your name" v-model="customerName"/>
      <base-form-input :required="true" type="text" label="Job title" v-model="jobTitle"/>
      <base-form-text-area label="Job description (optional)" v-model="jobDescription"></base-form-text-area>
      <div class="d-flex justify-content-end">
        <b-button type="button" variant="primary" @click="generateCoverLetter">Next</b-button>
      </div>

    </div>
    <div v-else-if="generatingState == 'generating'" class="d-flex justify-content-center flex-column">
      <h3 class="m-5">Generating your cover letter...</h3>
      <div class="d-flex justify-content-center">
        <grid-loader :loading="loader.state" :color="loader.color" :size="loader.size"></grid-loader>
      </div>
    </div>
    <div v-else-if="generatingState == 'preview'" class="d-flex justify-content-center flex-column">
      <h3 class="m-2">Preview</h3>
      <div class="d-flex justify-content-center flex-column">
                <base-form-text-area label="Cover letter" :propText="generatedCoverLetter"
                                     :disabled="true" :disableTextSelection="true"></base-form-text-area>
        <div class="d-flex justify-content-between m-2">
          <b-button type="button" variant="info" @click="resetGenerate">Back</b-button>
          <b-button type="button" variant="primary" @click="showEmailModal">Next</b-button>
        </div>
      </div>
    </div>
    <b-modal id="email-modal" title="Email" @ok="sendEmail" @cancel="resetGenerate">
      <label for="email">Enter your email to receive a link to the cover letter</label>
      <b-form-group label="" label-for="email">
        <b-form-input id="email" type="email" v-model="email" placeholder="Email address"></b-form-input>
      </b-form-group>
    </b-modal>
  </b-form>
</template>

<script>
import BaseFormInput from "@/components/base/BaseFormInput.vue";
import BaseFormSelect from "@/components/base/BaseFormSelect.vue";
import BaseFormTextArea from "@/components/base/BaseFormTextArea.vue";
import GridLoader from 'vue-spinner/src/GridLoader.vue'
import {generateHash} from "@/utils/hashing";


document.oncontextmenu = new Function("return false");

export default {
  name: "NewCoverLetter",
  components: {BaseFormTextArea, BaseFormSelect, BaseFormInput, GridLoader},
  data() {
    return {
      email: "",
      jobTitle: "",
      jobDescription: "",
      customerName: "",
      savedCoverLetter: {},
      loader: {
        state: false,
        color: '#f5cd79',
        size: '100px',
      },
      generatingState: "fill",
      generatedCoverLetter: ""
    };
  },
  watch: {
    startCoverLetter: function (newVal, oldVal) {
      if (newVal) {
        this.generatingState = "fill";
      }
    }
  },
  methods: {
    async sendEmail() {
      await this.$store.dispatch("email/sendEmail", {
        email: this.email,
        name: this.customerName,
        link: `${window.location.origin}/cover-letter/link/${this.savedCoverLetter.id}`,
      })
      await this.archiveCoverLetter();
      this.$bvModal.hide('email-modal');
      this.$notify({
        type: 'success',
        group: 'alerts',
        title: 'Email sent',
        text: 'Your cover letter has been sent to your email'
      });
    },
    async archiveCoverLetter() {
      await this.$store.dispatch("archive/createArchive", {
        id: generateHash(this.email),
        email: this.email,
        generated_id: this.savedCoverLetter.id,
        type: "cover_letter"
      })
    },

    showEmailModal() {
      this.$bvModal.show('email-modal');
    },
    resetGenerate() {
      this.generatingState = "fill";
    },
    validateCoverLetter() {
      if (this.jobTitle === "") {
        return false;
      }
      if (this.customerName === "") {
        return false;
      }
      return true;
    },
    async generateCoverLetter() {
      if (!this.validateCoverLetter()) {
        this.$notify({
          type: 'error',
          group: 'alerts',
          title: 'Required fields',
          text: 'The job title and your name are required fields.'
        });
        return;
      }
      this.loader.state = true;
      this.generatingState = "generating";
      let coverLetter = await this.$store.dispatch("coverLetter/generateCoverLetter", {
        jobTitle: this.jobTitle,
        jobDescription: this.jobDescription,
        customerName: this.customerName,
      })
      if (coverLetter) {
        this.loader.state = false;
        this.generatedCoverLetter = coverLetter;
        this.generatingState = "preview";
      }

      //   Save the cover letter to the database
      let savedCoverLetter = await this.$store.dispatch("coverLetter/saveCoverLetter", {
        jobTitle: this.jobTitle,
        jobDescription: this.jobDescription,
        customerName: this.customerName,
        coverLetter: coverLetter
      })

      this.savedCoverLetter = savedCoverLetter;

      if (savedCoverLetter) {
        this.$notify({
          type: 'success',
          group: 'alerts',
          title: 'Cover letter generated',
          text: 'Your cover letter has been generated'
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
const Hashes = require("jshashes");

function generateHash(string) {
  const SALT = `${process.env.VUE_APP_HASHING_SALT}`;
  return new Hashes.SHA1().hex_hmac(SALT, string);
}

function getStringHash(string) {
  let hash = 0,
    i,
    chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}

export {
  generateHash,
  getStringHash
};


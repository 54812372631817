<template>
  <b-form-group
      :label="label"
      :class="inputClass" :description="description">

    <b-form-input
        v-model="value"
        :type="type"
        :placeholder="label"
        :required="required"
        :disabled="disabled"
        @change="onChange"
    ></b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: "BaseFormInput",
  props: {
    inputClass: {
      type: String,
      default: "text-start p-1 fw-bold flex-fill",
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      default: "text"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: true,
      default: true
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    onChange() {
      this.$emit("input", this.value);
    }
  }

}
</script>

<style scoped>

</style>
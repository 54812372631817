<template>
  <b-row class="p-3">
    <label :class="inputClass">{{ label }}</label>
    <b-form-textarea
        :id="id"
        size="lg"
        v-model="propText"
        :placeholder="label"
        rows="30"
        max-rows="40"
        columns="30"
        @change="onChange"
        :disabled="disabled"
        :class="disableTextSelection ? 'disable-text-selection' : ''"
    ></b-form-textarea>
  </b-row>

</template>

<script>
export default {
  name: "BaseFormTextArea",
  props: {
    id: {
      type: String,
      required: false,
      default: "text-area-id"
    },
    inputClass: {
      type: String,
      default: "text-start p-1 fw-bold flex-fill",
    },
    label: {
      type: String,
      required: true,
    },
    propText: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableTextSelection: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      text: ""
    };
  },
  watch: {
    propText: function (newVal, oldVal) {
      this.text = newVal;
      ;
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.text);
    }
  }
}
</script>

<style scoped>
.disable-text-selection {
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

</style>
class Email {
    constructor() {
        this.url = "";
        this.elastic_search_username = process.env.VUE_APP_ELASTIC_SEARCH_USERNAME
        this.elastic_search_password = process.env.VUE_APP_ELASTIC_SEARCH_PASSWORD
    }


    async sendEmail(templateDynamicData) {
        const name = templateDynamicData.name
        const link = templateDynamicData.link
        const to = templateDynamicData.email
        // Move the body to a separate file
        const body = `Dear ${name}, <br><br>Thank you for using <b>Kazicraft</b>, our aim is to make job applications painless.<br>
            Your package has been created and is available through <br> <br>${link}. <br> <br>We wish you all the best in your job search. <br> <br><br>With love from Boston<br><b>Kazicraft Team<b>`
        let sendEmail = await window.Email.send({
            Host: "smtp.elasticemail.com",
            Username: this.elastic_search_username,
            Password: this.elastic_search_password,
            To: to,
            ToName: name,
            Body: body,
            From: "team@kazicraft.net",
            FromName: "Kazicraft Team",
            Subject: "A new work item has been created",
            IsTransactional: true,
        })
        return sendEmail
    }

}

export default new Email()
import {child, get, onValue, ref, set, update} from "firebase/database";
import {database} from "../../../firebase.config";
import {v4 as uuidv4} from 'uuid';


let environment = process.env.VUE_APP_ENVIRONMENT;

export class Database {

    constructor() {
        this.url = "";
    }

    async createRecord(record, recordUrl, model = null) {
        if (!record.id) {
            record.id = uuidv4();
        }
        const saveRecordUrl = `${environment}/${recordUrl}/${record.id}`;
        const mergedModel = {...record};
        await set(ref(database, saveRecordUrl), mergedModel);
        return {
            status: "success",
            message: "Record has been created",
            id: record.id
        }
    }

    async updateRecord(recordId, recordUrl, record) {
        const saveRecordUrl = `${environment}/${recordUrl}/${recordId}`;
        const updates = {};
        updates[saveRecordUrl] = {...record};
        await update(ref(database), updates)
        return {
            status: "success",
            message: "Record has been updated",
            id: recordId
        }
    }

    deleteRecord(recordId, recordUrl) {
        return this.updateRecord(recordId, `${environment}/${recordUrl}`, {});
    }

    getRecord(recordId, recordUrl) {
        const dbRef = ref(database);
        const saveRecordUrl = `${environment}/${recordUrl}/${recordId}`;
        return get(child(dbRef, saveRecordUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return {
                    "status": "success",
                    "message": "Record has been fetched",
                    "data": {...snapshot.val()},
                };
            } else {
                return {};
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    getRecords(recordUrl) {
        const dbRef = ref(database);
        return get(child(dbRef, `${environment}/${recordUrl}`)).then((snapshot) => {
            if (snapshot.exists()) {
                return {
                    "status": "success",
                    "message": "Records has been fetched",
                    "data": [...Object.values(snapshot.val())]
                }
            } else {
                return [];
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    getRecordsByQuery(recordUrl, queryKey, queryValue) {
        const dbRef = ref(database);
        return get(child(dbRef, `${environment}/${recordUrl}`)).then((snapshot) => {
            if (snapshot.exists()) {
                let records = Object.values(snapshot.val());
                let filteredRecords = records.filter((record) => {
                    return record[queryKey] === queryValue;
                })
                return {
                    "status": "success",
                    "message": "Records has been fetched",
                    "data": [...filteredRecords]
                }
            } else {
                return [];
            }
        }).catch((error) => {
            console.error(error);
        });
    }

}

export default new Database();
